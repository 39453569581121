import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { ConditionalLink } from "./ConditionalLink";
import './ComicNavigation.css';

export interface ComicNavigationProps {
    currentPage: number,
    lastPage: number,
    setIsComicLoading: Dispatch<SetStateAction<boolean>>
}
 
const ComicNavigation: FunctionComponent<ComicNavigationProps> = (props) => {
    const {currentPage, lastPage, setIsComicLoading} = props;
    const firstPage = 1;
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === lastPage;

    return (
        <nav className="comic-nav">
            <Link to={`/comic/${firstPage}`} className="comic-nav-item" onClick={() => setIsComicLoading(isFirstPage ? false : true)}>
                {"<< FIRST"}
            </Link>
            <ConditionalLink
                isDisabled={isFirstPage}
                targetPage={previousPage}
                linkText={"< PREVIOUS"}
                setIsComicLoading={setIsComicLoading}
            />
            <Link to="/archive" className="comic-nav-item">{"ARCHIVE"}</Link>
            <ConditionalLink 
                isDisabled={isLastPage}
                targetPage={nextPage}
                linkText={"NEXT >"}
                setIsComicLoading={setIsComicLoading}
            />
            <Link to={`/comic/${lastPage}`} className="comic-nav-item" onClick={() => setIsComicLoading(isLastPage ? false : true)}>
                {"LAST >>"}
            </Link>
        </nav>
     );
}
 
export default ComicNavigation;