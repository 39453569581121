import React, { FunctionComponent } from 'react';
import { cast } from '../../assets/Characters';
import './Cast.css';
import Character from './Character';

const Cast: FunctionComponent<{}> = () => {
    return (
        <div className="content-container">
            {cast.map((group) => {
                return (
                    <React.Fragment>
                        <div className="cast-header">
                            <h2 className="cast-header">{group.faction}</h2>
                        </div>
                        {group.characters.map((character) => 
                            <Character 
                                name={character.name}
                                description={character.description}
                                triviaQuestion={character.triviaQuestion}
                                triviaAnswer={character.triviaAnswer}
                                firstAppearance={character.firstAppearance}
                                image={character.image}
                            />
                        )}
                    </React.Fragment>
                )
            })}
        </div>
    );
}

export default Cast;
