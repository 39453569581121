import { FunctionComponent } from "react";

interface BlogPostProps {
    title: string,
    content: string,
    datePublished: string
}
 
const BlogPost: FunctionComponent<BlogPostProps> = (props) => {
    const {title, content, datePublished} = props;

    return ( 
        <div className="textbox">
            <h2>{title}</h2>
            <p>{content}</p>
            <span className="textbox-footer">
                Published on {datePublished} by Erik Holfelder & Jake Tallent.
            </span>
        </div>
     );
}
 
export default BlogPost;