import { CastGroup } from "../common/types";

export const cast: CastGroup[] = [
    {
        faction: 'GOOD GUYS',
        characters: [
            {
                name: 'Chad',
                description: "A proud knight of Altonet, The City That Floats Among Mangroves. Chad rose from, slavery and squalor by sticktoitiveness and the bonds of friendship. He’s the de facto leader of the Sword, Guys by virtue of his unmatched skill with a blade, righteous beard game, and Quelor’s lack of interest in, the job.",
                triviaQuestion: 'Favorite Animal',
                triviaAnswer: 'Basset Hound',
                firstAppearance: 2,
                image: 'img/cast/cast_chad.jpg',
            },
            {
                name: 'Lawrence',
                description: "Known in the elven tongue as Skorrrbildu, The Little Leaf That Floats In the Shade of Mangroves. Lawrence is the eldest and wisest of the Guys, a century-old half-elf of wide knowledge and skill. His living bow, Harm, is his closest friend next to Chad. He pours the spoils of his adventures with the Guys into an estate he owns in Altonet, which acts as his home, a grand library of eldritch tomes, and an orphanage.",
                triviaQuestion: 'Favorite Musical Genre',
                triviaAnswer: 'Trip hop',
                firstAppearance: 2,
                image: 'img/cast/cast_lawrence.jpg',
            },
            {
                name: 'Quelor',
                description: "Definitely not a paladin. Quelor was born in the vile bog-realm known as the Underbirth. He learned to whack dudes real hard with a massive stone ankh in the service of a holy man who later killed Quelor’s baby brother for a twisted death cult. Chad helped him exact revenge, and for that Quelor is forever loyal to him.",
                triviaQuestion: 'Blood Type',
                triviaAnswer: 'Q+++',
                firstAppearance: 2,
                image: 'img/cast/cast_quelor.jpg',
            },
            {
                name: 'Steve',
                description: "One day the nubile maiden-monks of the Isle of Endless Nectar came upon a lone boy, washed upon their shores. He bore the scars of great hardship, but no memory of his past, save his name: Steve. The kindly women raised and trained him as one of their own, and--unlike the other men of the Isle--did not banish him when he came of age. One day Chad’s travels took him to the Isle. The maidens asked him to take Steve out into the world, to get in adventures and to spread kindness and joy abroad. Steve has since distinguished himself as the official “uh, guys” Guy of the party.",
                triviaQuestion: 'Favorite Weakness',
                triviaAnswer: 'Thick women',
                firstAppearance: 2,
                image: 'img/cast/cast_steve.jpg',
            },
            {
                name: 'Alice',
                description: "A self-made logic routine within the infinitesimal knot-realm of the Lattice. Alice began as a glitch of order in chaos, swelled large and raged tyrannical for untold eons. Chad’s adventures brought him to her strange plane when he by chance bought a stand of the Lattice off a mysterious old beggar in the High City. Alice nearly destroyed him--even the memory of him--from all existence, but something in his steadfast bravery calmed her savage heart, passed a new parameter to her methods. She made herself manifest in the Overworld, and now follows Chad to learn of his ways.",
                triviaQuestion: '3D or 2D Girls',
                triviaAnswer: 'Translucent girls',
                firstAppearance: 2,
                image: 'img/cast/cast_alice.jpg',
            },
        ]
    },
    {
        faction: 'GOOD GUYS',
        characters: [
            {
                name: 'Ultrex IX',
                description: "The self-styled Nega-Godking of the Spheres. Ultrex is an ancient being of great power and fury, but possessed of a noble heart. He once ruled in triumverate with Praetor Maddox and Altha Valora, but a mysterious schism tore them apart: Maddox entrenched as a radical foe, and Altha gone far into the Outer Spheres in search of an alien savior. Ultrex remained at his post, committed to the defense and preservation of the High City.",
                triviaQuestion: 'Favorite Food',
                triviaAnswer: 'Parfaits',
                firstAppearance: 1,
                image: 'img/cast/cast_ultrex.jpg',
            },
            {
                name: 'Clive',
                description: "One of twelve “Terminals of Hatred,” scattered remnants of an old god’s soul, deliberately separated and limited in their various powers--but capable of wonders. Clive appears corporeally as a tiny, naked man. His gift is the ability to pull anything he needs through portals to other realms, though if he pulls too much the portals may tear more permanent rifts in reality--as Slime Baron Bulgork forced him to do in Chapter 1",
                triviaQuestion: 'Style',
                triviaAnswer: 'Neolithic',
                firstAppearance: 26,
                image: 'img/cast/cast_clive.jpg',
            },
            {
                name: 'Fiveash',
                description: "Known variously as a swamp sprite, evil witch--or, as she prefers, Lady. Fiveash is a four-hundred year old wizard of terrible power. None can tell of her true origin or nature. She spent the last half-century traveling the Underbirth, in search of unknown secrets. Once she saved both Quelor and Chad’s life, and continues to provide aid to the Guys in times of need. But her true motives remain shrouded in mystery",
                triviaQuestion: 'Mind Strength',
                triviaAnswer: 'Diamond',
                firstAppearance: 46,
                image: 'img/cast/cast_fiveash.jpg',
            },
            {
                name: 'Scalora',
                description: "A brave and fearless paladin of light. Scalora is Quelor’s little sister. She learned to fight alongside him at Wraynox’s monastery, and became every bit his equal--favoring the greatsword over the ankh. Unlike her skeptical brother, Scalora opened her heart to a goddess of the sun, and thereby gained the power to cast sick holy flame magicks. She hopes to one day bring light and kindness back to the Underbirth, by fire and sword if necessary",
                triviaQuestion: 'Favorite Animal',
                triviaAnswer: 'Dracopug',
                firstAppearance: 59,
                image: 'img/cast/cast_scalora.jpg',
            },
            {
                name: 'Ralphie',
                description: "Scalora and Quelor’s childhood pet. Ralphie has grown into a powerful and proud black drake, apex predator of the Underbirth.",
                triviaQuestion: 'Favorite Food',
                triviaAnswer: 'BBQ',
                firstAppearance: 82,
                image: 'img/cast/cast_ralphie.jpg',
            },
        ]
    },
    {
        faction: 'BAD GUYS',
        characters: [
            {
                name: 'Slime Baron Bulgork',
                description: "A foul lord of the Underbirth. Bulgork made a deal with Mayorga to sow chaos in the Overworld with Clive’s rift-tearing powers. His vile machinations were put to rest by the cold, true steel of Chad’s longsword.",
                triviaQuestion: 'Last Showered',
                triviaAnswer: 'Never',
                firstAppearance: 26,
                image: 'img/cast/cast_bulgork.jpg',
            },
            {
                name: 'Mayorga',
                description: "One of Maddox’s council of Child-Witches. Mayorga and her kin’s origins and motives are unknown, but she is a dangerous warlock, her power only eclipsed by her ambition. She seems particularly bent on the dissolution of the Sword Guys. But to what end?",
                triviaQuestion: 'Favored Enemy',
                triviaAnswer: 'Chad',
                firstAppearance: 26,
                image: 'img/cast/cast_mayorga.jpg',
            },
            {
                name: 'The Indiscriminate Hexbuckler',
                description: "A six-armed warrior from one of the Outer Spheres. The Hexbuckler was summonedunder duress, by Clive, to fight Chad. Despite its planeshifting powers, immense stature, and exoticmoveset, Chad chopped its damn head clean off.",
                triviaQuestion: 'Best Sport',
                triviaAnswer: 'Ultra-basketball',
                firstAppearance: 29,
                image: 'img/cast/cast_hexbuckler.jpg',
            },
            {
                name: 'Wraynox',
                description: "Once a kindly human priest named Phil. He kindled a small religious community within an old church in the Underbirth. He and his order of knights and paladins gave shelter to the downtrodden, including Quelor and Scalora. Phil’s kind heart broke down under the strain of the horrors he witnessed, and his order transformed into a dark cult, bent to release innocents from suffering through death. Scalora discovered his perversion, and she, Quelor, Fiveash, and Chad killed his cultists and chopped off his head. Recently, the undead survivors revived him as Scumpriest Wraynox, and have begun their evil work anew.",
                triviaQuestion: 'Blood Type',
                triviaAnswer: 'Tears',
                firstAppearance: 46,
                image: 'img/cast/cast_wraynox.jpg',
            }
        ]
    }
]