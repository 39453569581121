import { Chapter, Page } from "../common/types";

export const chapters: Chapter[] = [
    {
        chapterTitle: "1.  Sword Guys",
        chapterNumber: 1,
        pageStart: 1,
        pageEnd: 44
    },
    {
        chapterTitle: "2.  The Underbirth",
        chapterNumber: 2,
        pageStart: 45,
        pageEnd: 147
    }
]

// Import the Page data from json
// Stored as json for easy access by PowerShell to create rss feed
export const pages: Page[] = require('./pageData.json')