import { FunctionComponent } from 'react';
import './About.css';

const About: FunctionComponent<{}> = () => {
    return (
        <div className="content-container">
            <div id="about-container">
                <h2>KNOWLEDGE COMPENDIUM</h2>
                <p>
                <div className="image-right">
                    <img 
                        src="../../img/assets/knowledgeCompendium.png"
                        alt="Lawrence and a ghostly figure seeking deep knowledge in ancient tomes"
                        className="shrinking-image"
                    />
                
                </div>
                    Sword Guys is a fantasy / adventure / comedy webcomic, inspired by our favorite books, movies, shows, and video games.
                    <br /><br />
                    Erik Holfelder and Jake Tallent write the story together. Erik draws the art, and Jake writes the scripts.
                    <br /><br /> 
                    We put the comic on hiatus back in 2017, but we may continue the story in some form eventually.
                </p>
            </div>
        </div>
    );
}
 
export default About;
