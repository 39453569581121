import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { chapters, pages } from '../../assets/ComicData';
import { Chapter, Page } from '../../common/types';
import './Archive.css';

const renderChapters = (chapters: Chapter[]) => {
    return (
        chapters.map((chapter) => {
            return (
                <div className="chapter-container">
                    <li><h3>{chapter.chapterTitle}</h3></li>
                    <ol start={chapter.pageStart}>
                        {renderPageLinks(pages.slice(chapter.pageStart-1, chapter.pageEnd))}
                    </ol>
                </div>
            )
        })
    )
}

const renderPageLinks = (pages: Page[]) => {
    return (
        pages.map((page) => {
            return (
                <li key={page.pageNumber}>
                    <Link to={`/comic/${page.pageNumber}`}>{page.title}</Link>
                </li>
            )
        })
    )
}

const Archive: FunctionComponent<{}> = () => {
    return (
        <div className="content-container">
            <div id="archive-left">
                <h2>Archive</h2>
                <ol>
                    {renderChapters(chapters)}
                </ol>
            </div>

            <div id="archive-right">
                <img 
                    src="../../img/assets/archive.png"
                    alt="Alice and Steve read arcane tomes from Lawrence's bookshelf"
                    className="shrinking-image"
                />
            </div>
        </div>
    );
}
 
export default Archive;