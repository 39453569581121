import { FunctionComponent, useState } from 'react';
import './Comic.css';
import { Link, useParams } from "react-router-dom";
import { pages } from '../../assets/ComicData';
import ComicNavigation from './ComicNavigation';
import BlogPost from './BlogPost';
import Comments from './Comments';

const Comic: FunctionComponent<{}> = () => {
    const [isComicLoading, setIsComicLoading] = useState<boolean>(true);

    // Get pageNumber from route
    const { pageNumber } = useParams();
    // Use pageNumber if present from route, otherwise assume last page.
    const currentPage = pageNumber ? +pageNumber : pages.length;
    // Calculate the next page, unless we're on the last page
    const nextPage = currentPage !== pages.length ? currentPage+1 : currentPage;
    const comicImg = `../../img/comics/sg_${currentPage}.jpg`;
    const isLastPage = currentPage === pages.length;

    return ( 
        <div className="comic-container">
            <div className="comic-content">
                <Link to={`/comic/${nextPage}`} onClick={() => setIsComicLoading(isLastPage ? false : true)}>
                    {isComicLoading ? <div className='loading-comic'>loading...</div> : null}
                    <img 
                        src={comicImg}
                        style={isComicLoading ? { display: 'none' } : {}}
                        alt={`Sword Guys comic ${currentPage}`} 
                        className="shrinking-image" 
                        onLoad={() => setIsComicLoading(false)} />
                </Link>
            </div>
            <ComicNavigation currentPage={currentPage} lastPage={pages.length} setIsComicLoading={setIsComicLoading} />
            <BlogPost 
                title={pages[currentPage-1].blogTitle} 
                content={pages[currentPage-1].blogPost} 
                datePublished={pages[currentPage-1].datePublished} 
            />
            <Comments currentPage={currentPage} />
        </div>
     );
}
 
export default Comic;