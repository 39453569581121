import React from 'react';
import './Header.css';
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <div className="header">
            <div className="header-content">
                <a href="/" className="header-text"><h1>SWORD GUYS</h1></a>
                <div className="fill" />
                <nav className="header-nav">
                    <Link to="/" className="nav-item">comic</Link>
                    <Link to="/archive" className="nav-item">archive</Link>
                    <Link to="/about" className="nav-item">about</Link>
                    <Link to="/cast" className="nav-item">cast</Link>
                </nav>
            </div>
        </div>
    );
}

export default Header;