import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { Link } from "react-router-dom";

interface ConditionalLinkProps {
    isDisabled: boolean,
    targetPage: number,
    linkText: string,
    setIsComicLoading: Dispatch<SetStateAction<boolean>>
}

export const ConditionalLink: FunctionComponent<ConditionalLinkProps> = (props) => {
    const {isDisabled, targetPage, linkText, setIsComicLoading} = props;

    if (isDisabled) return <div className="disabled-link comic-nav-item">{linkText}</div>;

    return <Link to={`/comic/${targetPage}`} className="comic-nav-item" onClick={() => setIsComicLoading(true)}>{linkText}</Link>;
}