import './Footer.css';

const Footer = () => {
    const date = new Date();

    return (
        <div className="footer">©2015-{date.getFullYear()} by Erik Holfelder & Jake Tallent</div>
    );
}

export default Footer;