import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import About from './components/about/About';
import Archive from './components/archive/Archive';
import Cast from './components/cast/Cast';
import Comic from './components/comic/Comic';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';

function App() {
  // Calling useLocation forces a re-render on navigation, allowing the random bg images to work
  const location = useLocation();
  const getBackgroundImgUrl = () => `"${process.env.PUBLIC_URL}/img/bg/bg_0${(Math.floor(Math.random() * 5)) + 1}.jpg"`;

  return (
    <div className="app-container">
      <div id="back-image" style={{backgroundImage: `url(${getBackgroundImgUrl()})`}} />
      <Header />
      <div className="body-container">
          <Routes>
              <Route path="/archive" element={<Archive />} />
              <Route path="/about" element={<About />} />
              <Route path="/cast" element={<Cast />} />
              <Route path="/comic/:pageNumber" element={<Comic />} />
              <Route path="/" element={<Comic />} />
          </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
