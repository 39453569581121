import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import './Cast.css';

interface CharacterProps {
    name: string,
    description: string,
    firstAppearance: number,
    triviaQuestion: string,
    triviaAnswer: string,
    image: any
}
 
const Character: FunctionComponent<CharacterProps> = (props) => {
    const {name, description, image, triviaQuestion, triviaAnswer, firstAppearance} = props;

    return ( 
        <div className="cast-container">
            <div className="cast-body-left">
                <img className="portrait-border" src={image} alt={`Portrait of ${name}`} />
            </div>
            <div className="cast-body-right">
                <h3>{name}</h3>
                <p className="castDescription"> {description} </p>
                <i>First Appearance:</i><Link to={`/comic/${firstAppearance}`}> Page {firstAppearance}.</Link>
                <i>{` ${triviaQuestion}`}:</i> {triviaAnswer}. <br /><br />
            </div>
        </div>
     );
}
 
export default Character;