import Disqus from "disqus-react";
import { FunctionComponent } from "react";

interface CommentsProps {
    currentPage: number
}

const Comments: FunctionComponent<CommentsProps> = (props) => {
    const {currentPage} = props;

    return (
        <div className="textbox">
        <h3>Reader Comments</h3>
        <Disqus.DiscussionEmbed
            shortname='sword-guys'
            config={
                {
                    url: `http://www.sword-guys.com/comic/${currentPage}`
                }
            }/>
        </div>
    )
};

export default Comments;